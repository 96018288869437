<messages>["./Contact"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <base-layout mw3>
      <v-col cols="12">
        <v-alert
          v-model="isSuccessAlertVisible"
          type="success"
          dismissible>
          <i18n path="create.success">
            <template #link>
              <router-link
                class="success-alert-link"
                :to="{name: 'contact.view', params: { registry, handle }}"
                v-text="handle"/>
            </template>
            <template #clipboard>
              <notification
                v-if="handle !== null"
                v-model="showCopyNotification"
                :text="$t ('general.notification.copied')">
                <template #activator="{ on }">
                  <a
                    v-t="'create.handleToClipboard'"
                    class="success-alert-link"
                    v-on="on"
                    @click="copyHandleToClipboard"/>
                </template>
              </notification>
            </template>
          </i18n>
        </v-alert>
      </v-col>
    </base-layout>

    <v-alert
      id="invalidDataAlert"
      v-t="'general.invalid.form'"
      type="error"
      :value="isInvalidDataAlertVisible"/>

    <contact-create-update
      :is-create="true"
      :registry-id="this.$route.query.registry"
      :client-id="this.$route.query.client"
      @success="onSuccess"
      @failure="onFailure"
      @dataValidityChanged="onDataValidityChanged"
      @cancel="back"/>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import goTo from 'vuetify/lib/services/goto'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import Notification from '@/app/core/components/Notification'

  import ContactCreateUpdate from './ContactCreateUpdate'
  import {mapMutations} from 'vuex'

  export default {
    name: 'ContactCreate',

    components: {
      BaseLayout,
      ContactCreateUpdate,
      Notification
    },

    data () {
      return {
        isSuccessAlertVisible: false,
        handle: null,
        registry: null,
        isInvalidDataAlertVisible: false,
        showCopyNotification: false
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),

      onSuccess ({handle, registry}) {
        this.handle = handle
        this.registry = registry
        this.isSuccessAlertVisible = true
        this.addEvent ({
          type: 'success',
          message: this.$t ('create.success', {link: handle}),
          objects: [{name: handle, link: {name: 'contact.view', params: {registry, handle}}}]
        })
      },

      onFailure () {
        this.isSuccessAlertVisible = false
        this.scrollToAlert ()
      },

      onDataValidityChanged (isValid) {
        console.warn ('onDataValidityChanged', isValid)
        this.isInvalidDataAlertVisible = !isValid
        if (this.isInvalidDataAlertVisible) this.scrollToAlert ()
      },

      back () {
        this.$router.back ()
      },

      copyHandleToClipboard () {
        this.$copyText (this.handle)
        this.showCopyNotification = true
      },

      scrollToAlert () {
        goTo ('#invalidDataAlert')
      }
    }
  }
</script>

<!--
================================================================================
  Styling
================================================================================
-->

<style scoped>
a.success-alert-link {
  text-decoration: underline;
  color: #d5ffff
}
</style>
